/* 外部方法 */
import { DateTime } from 'luxon';
import { LogLevel } from '@microsoft/signalr';

/* 內部方法 */
import { isDevelopment } from '../utils/helper';

/* 型別 */
import type { ILogger } from '@microsoft/signalr';
import type { LoggerSite } from '../store/socket';

/** 此 Class 為 SignalR configureLogging 使用 */
export default class SignalrLogger implements ILogger {
  constructor(public site: LoggerSite) {}

  logArray: { [key: string]: string }[] = [];

  log(logLevel: LogLevel, message: string) {
    const { Information } = LogLevel;

    if (logLevel === Information && isDevelopment) {
      console.log('[Infomation]', message);
    }

    this.logArray.push({
      LogLevel: `${logLevel}`,
      LogTime: `${DateTime.utc()}`,
      Message: message
    });

    if (this.logArray.length > 30) {
      this.logArray.shift();
    }
  }
}
