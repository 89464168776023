/* 外部方法 */
import axios from 'axios';

/* 內部方法 */
import logRecorderService from '../api/logRecorderService';
import useI18n from '../composables/useI18n';

/* 型別 */
import { LogLevel } from '@microsoft/signalr';

const { t } = useI18n();

const getSite = () => {
  const HOST = window.location.host;

  const isAdmin = /(admin|8082)/gi.test(HOST);
  const isTablet = /(tablet|8083)/gi.test(HOST);
  const isDealer = /(dealer|8081)/gi.test(HOST);

  if (isAdmin) return 'admin';
  if (isTablet) return 'tablet';
  if (isDealer) return 'dealer';

  return '';
};

let customErrorInfo: Record<string, string | number> = {};

export const editCustomErrorInfo = (info: Record<string, string | number>) => {
  customErrorInfo = { ...customErrorInfo, ...info };
};

export default class CustomError extends Error {
  code: string;

  type: string;

  data?: unknown | undefined;

  i18nArguments: any;

  originalError?: Error;

  constructor(
    type: string,
    code: string,
    i18nArguments: any = null,
    originalError = {} as Error,
    data: unknown = undefined
  ) {
    const translatedErrorMessage = i18nArguments ? t(`${type}.${code}`, i18nArguments) : t(`${type}.${code}`);

    super(translatedErrorMessage);

    this.code = code;
    this.type = type;
    this.data = data;
    this.i18nArguments = i18nArguments;
    this.originalError = originalError;

    // 若 stack 沒有值，就以 meesage 填充
    if (!this.stack) this.stack = this.message;

    // 填充 call stack，讓原本的 call stack 也能顯示。
    if (this.stack && originalError.stack) {
      const lineCount = (this.message.match(/\n/g || [])?.length || 0) + 1;
      this.stack = this.stack
        .split('\n')
        .slice(0, lineCount + 1)
        .join('\n');
      this.stack += `\n${originalError.stack}`;
    }

    // 將錯誤紀錄至後端 ELK
    const site = getSite();

    // 如果是 ajax 相關錯誤，則另發錯誤記錄
    if (axios.isAxiosError(originalError)) {
      this.stack = `It's ajax error, detail is in another report.\n\n${this.stack}`;

      if (site) {
        logRecorderService.postLog({
          Msg: [
            {
              Time: new Date(),
              LogLevel: LogLevel.Trace,
              ...customErrorInfo,
              OriginalErrorResponse: originalError.response
            }
          ],
          LoggerName: site
        });
      }
    }

    const payload = {
      Msg: [
        {
          Time: new Date(),
          LogLevel: LogLevel.Error,
          ...customErrorInfo,
          ErrorStack: this.stack
        }
      ],
      LoggerName: site
    };

    if (site) logRecorderService.postLog(payload);
  }

  get id() {
    return `${this.type}.${this.code}`;
  }
}
