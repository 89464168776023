/* 外部方法 */
import { Loader } from 'resource-loader';

/*
 * 取得 GlobEager 的定義
 *
 * ref: 實例化表達 https://github.com/microsoft/TypeScript/pull/47607#issuecomment-1058144708
 */
declare const glob: ImportMeta['glob'];
type Glob = typeof glob<Record<string, string>>;

export default class SourceLoader {
  readonly loader = new Loader();

  constructor(importedFileList: ReturnType<Glob>) {
    const importedFilePathList = Object.values(importedFileList).map(
      (path) => new URL(path.default, window.location.origin).href
    );

    this.loader.add(importedFilePathList);
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loader.onComplete.add(() => resolve());
      this.loader.onError.add(() => reject());

      this.loader.load();
    });
  }
}
