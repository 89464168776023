/* 外部方法 */
import router from '../router';

/* 內部方法 */
import store, { pinia } from '@/store';
import { hasAuthPermission } from '@/utilities/routerHelper';

/* 型別 */
import type { App, DirectiveBinding } from 'vue';

const { getFlagMapValue } = store.useFlagMapStore(pinia);
const rootStore = store.useRootStore(pinia);

// enum AuthType {
//   CREATE, // 1
//   DELETE, // 2
//   UPDATE, // 4
//   SEARCH // 8
// }

export default {
  install: (app: App) => {
    app.directive('auth', {
      mounted(el: HTMLElement, binding: DirectiveBinding<string>) {
        // 以 directive 傳入的參數並強制轉為大寫
        const elementAuthMethod = binding.value.toLocaleUpperCase();

        // 元素需要的權限代碼
        const elementAuthCode = Number(getFlagMapValue('AuthType', elementAuthMethod));

        if (!hasAuthPermission(router.currentRoute.value.meta.Uri, elementAuthCode, rootStore.siderbar))
          el.style.display = 'none';
      }
    });
  }
};
