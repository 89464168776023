/* 外部方法 */
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

/* 內部方法 */
import { parentFixer, nestFlatten, isDevelopment } from '@sms/common/utils/helper';

/* API */
import locationService from '@/api/ajax/locationService';

/* 型別 */
import type LocationSet from '@sms/common/models/LocationSet';

export default defineStore('location', () => {
  const locations = ref<LocationSet[]>([]); // 所有地區列表 (按照權限過濾)
  const locationsAllPermission = ref<LocationSet[]>([]); // 所有地區列表 (不按照權限過濾)

  const locationFlat = computed(() => nestFlatten(parentFixer(locations.value))); // 所有地區列表 (按照權限過濾並攤平)
  const locationAllpermissionFlat = computed(() => nestFlatten(parentFixer(locationsAllPermission.value))); // 所有地區列表 (不按照權限過濾並攤平)

  const fetchLocationList = async () => {
    try {
      const resp = await locationService.getMemberLocationTree({ OrderBy: 'Name' });
      locations.value = resp.data.Data;
    } catch (error) {
      if (isDevelopment) console.error(error);
    }
  };

  const fetchLocationListAllPermission = async () => {
    try {
      const resp = await locationService.getLocationTree({ OrderBy: 'Name' });
      locationsAllPermission.value = resp.data.Data;
    } catch (error) {
      if (isDevelopment) console.error(error);
    }
  };

  return {
    locations,
    locationsAllPermission,
    locationFlat,
    locationAllpermissionFlat,
    fetchLocationList,
    fetchLocationListAllPermission
  };
});
