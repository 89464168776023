<template>
  <div
    :class="[
      'flex flex-col justify-center w-full max-w-[376px] min-h-[79px] p-[18px] rounded-md shadow pointer-events-auto',
      bgColor,
      textColor,
      positionClass
    ]"
  >
    <div class="flex">
      <div class="grid grid-cols-[16px_1fr] gap-x-3 text-sm grow">
        <div class="flex justify-center items-center">
          <SIcon :icon="icon" :class="['w-5 h-5', iconTextColor]"></SIcon>
        </div>

        <p class="font-medium leading-5">{{ title }}</p>

        <template v-if="subtitle && !subtitleComponent">
          <p v-if="!isHtml" class="col-start-2 leading-5">{{ subtitle }}</p>
          <div v-else class="col-start-2 leading-5" v-html="subtitle"></div>
        </template>

        <template v-else>
          <div></div>
          <component :is="subtitleComponent" v-bind="subtitleComponentProps" />
        </template>
      </div>

      <div v-if="closable" class="flex items-center justify-center">
        <SIcon :icon="mdiClose" class="w-5 h-5 cursor-pointer" @click="emit('close', id)"></SIcon>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { computed, type Component } from 'vue';

/* 外部組件 */
import { mdiAlertCircle, mdiCheckCircle, mdiClose } from '@mdi/js';

/* 內部組件 */
import SIcon from './../../SIcon/SIcon.vue';

/* 型別 */
interface Props {
  id: string;
  type?: 'success' | 'warning' | 'error';
  title: string;
  subtitle?: string;
  position?: 'left' | 'center' | 'right';
  closable?: boolean;
  isHtml?: boolean;
  subtitleComponent?: Component;
  subtitleComponentProps?: any;
}

interface Emits {
  (e: 'close', id: string): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

/* 模板樣式相關 */
const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return mdiCheckCircle;
    case 'warning':
    case 'error':
      return mdiAlertCircle;
    default:
      return undefined;
  }
});

const iconTextColor = computed(() => {
  switch (props.type) {
    case 'success':
      return 'text-green-400';
    case 'warning':
      return 'text-amber-300';
    case 'error':
      return 'text-red-300';
    default:
      return null;
  }
});

const textColor = computed(() => {
  switch (props.type) {
    case 'success':
      return 'text-green-800';
    case 'warning':
      return 'text-amber-700';
    case 'error':
      return 'text-red-700';
    default:
      return null;
  }
});

const bgColor = computed(() => {
  switch (props.type) {
    case 'success':
      return 'bg-green-50';
    case 'warning':
      return 'bg-amber-50';
    case 'error':
      return 'bg-red-50';
    default:
      return null;
  }
});

const positionClass = computed(() => {
  switch (props.position) {
    case 'left':
      return 'self-start';
    case 'center':
      return 'self-center';
    case 'right':
      return 'self-end';
    default:
      return 'self-center';
  }
});
</script>
