/* eslint-disable object-curly-newline */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */

/* 外部方法 */
import { h, render } from 'vue';
import SProgressCircular from '../SProgressCircular/SProgressCircular.vue';

/* 型別 */
import type { App, DirectiveBinding } from 'vue';

interface Instance {
  uid: string;
  height: number;
  resizeObserve: ResizeObserver;
}

const instances: Instance[] = [];
let id = 0;

/** 渲染 */
const renderComponent = async (el: HTMLElement) => {
  render(null, el);

  const position = getComputedStyle(el).getPropertyValue('position');
  const scrollHeight = el.scrollHeight;

  if (position !== 'absolute' && position !== 'fixed' && position !== 'relative') {
    el.classList.add('s-loading__relative');
  }

  const svg = h(SProgressCircular, { class: '!w-12 !h-12 text-white' });

  const div = h(
    'div',
    {
      class: 'absolute inset-0 flex justify-center items-center bg-gray-400 opacity-75 rounded-[inherit] z-[1000]',
      style: { height: `${scrollHeight}px` }
    },
    [svg]
  );

  render(div, el);
};

/** 建立 */
const createInstance = (el: HTMLElement, binding: DirectiveBinding<boolean>) => {
  const uid = (id += 1);

  el.id = `${uid}`;

  const instance = {
    uid: el.id,
    height: 0,
    resizeObserve: new ResizeObserver((entries) => {
      const oldHeight = instance.height;
      instance.height = entries[0].contentRect.height;
      if (oldHeight !== instance.height) renderComponent(el);
    })
  };

  instances.push(instance);

  if (binding.value) instance.resizeObserve.observe(el);
};

/** 移除 */
const destroyInstance = (el: HTMLElement) => {
  const instance = instances.find((x) => x.uid === el.id);
  if (!instance) return;

  el.classList.remove('s-loading__relative');
  el.id = '';
  instance.resizeObserve.unobserve(el);
  instances.splice(instances.indexOf(instance), 1);

  render(null, el);
};

/** 插件注入 */
export default {
  install: (app: App) => {
    app.directive('loading', {
      mounted(el: HTMLElement, binding) {
        createInstance(el, binding);
      },

      updated(el: HTMLElement, binding) {
        if (binding.value !== binding.oldValue) {
          const instance = instances.find((x) => x.uid === el.id);

          if (!instance) {
            createInstance(el, binding);
            return;
          }

          if (binding.value && instance) instance.resizeObserve.observe(el);
          if (!binding.value && instance) destroyInstance(el);
        }
      },

      beforeUnmount(el: HTMLElement) {
        destroyInstance(el);
      }
    });
  }
};
