/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type FlagMap from '@sms/common/models/FlagMap';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';

export default {
  getAll(): AxiosPromise<ResponseBaseModel<FlagMap[]>> {
    return api.get('/FlagMap');
  }
};
