/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';
import type SiderbarItem from '@sms/common/interfaces/SiderbarItem';
import type MemberSet from '@sms/common/models/MemberSet';

interface AuthResponse {
  token: string;
  member: MemberSet;
}

export default {
  check(): AxiosPromise<ResponseBaseModel<boolean>> {
    return api.get('/Auth/Check');
  },

  login(u: string, p: string, c: string): AxiosPromise<ResponseBaseModel<AuthResponse>> {
    return api.post('/Auth', { u, p, c });
  },

  logout(): AxiosPromise<ResponseBaseModel<string>> {
    return api.delete('/Auth');
  },

  getSiderbar(): AxiosPromise<ResponseBaseModel<SiderbarItem[]>> {
    return api.get('/Auth/Siderbar/Current');
  },

  getSiderbarAll(): AxiosPromise<ResponseBaseModel<SiderbarItem[]>> {
    return api.get('/Auth/Siderbar/All');
  },

  changePassword(oPwd: string, nPwd: string): AxiosPromise<ResponseBaseModel<string>> {
    return api.put('/Auth/ChangePassword', { oPwd, nPwd });
  },

  changeLangType(LangType: string): AxiosPromise<ResponseBaseModel<string>> {
    return api.put('/Auth/ChangeLangType', { LangType });
  },

  info(): AxiosPromise<ResponseBaseModel<MemberSet>> {
    return api.get('/Auth/Info');
  }
};
