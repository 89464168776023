/* 外部方法 */
import { DateTime } from 'luxon';
import { cloneDeep, sortBy } from 'lodash-es';
import { h } from 'vue';
import stringWidth from 'string-width';

/* 型別 */
import type LocationSet from '../models/LocationSet';
import type ErrorExclusionSet from '../models/ErrorExclusionSet';
import type SystemFileSet from '../models/SystemFileSet';
import type SiderbarItem from '../interfaces/SiderbarItem';
import type EventRecordSet from '../models/EventRecordSet';

export interface NestModel<T> {
  Parent?: T;
  InverseParent: T[];
}

export type ValueOf<T> = T[keyof T];

export const emptyDataURL = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"></svg>';

export const viewLists = Object.keys(import.meta.glob('@/views/**/*.vue')).map((route) =>
  route.replace(/.*views/, '.')
);

export const initUrlPath = window.location.hash.replace('#', '');

/** 本機開發環境 */
export const isLocal = import.meta.env.VITE_BUILDENV === 'dev';

/** QA 環境 */
export const isQA = import.meta.env.VITE_BUILDENV === 'test';

/** stage 環境 */
export const isStage = import.meta.env.VITE_BUILDENV === 'stage';

/** product 環境 */
export const isProd = import.meta.env.VITE_BUILDENV === 'prod';

export const isDevelopment = import.meta.env.DEV || import.meta.env.VITE_TEST === 'true';

/**
 * 巢狀 readonly
 * source: github: @microsoft/TypeScript/#13923
 */
export type DeepReadonly<T> = { readonly [P in keyof T]: DeepReadonly<T[P]> };

/** 事後填補 Parent 欄位 */
export const parentFixer = <T extends NestModel<T>>(input: T[]): T[] => {
  const cache: T[] = [...input];

  while (cache.length) {
    const item = cache.pop();
    if (item?.InverseParent.length) {
      item.InverseParent.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        x.Parent = item;
      });
      cache.push(...item.InverseParent);
    }
  }

  return input;
};

/** 巢狀結構打平 */
export const nestFlatten = <T extends NestModel<T>>(input: T[]): T[] => {
  const result: T[] = [];

  input.forEach((x) => {
    result.push(x);

    if (Array.isArray(x.InverseParent)) {
      result.push(...nestFlatten(x.InverseParent));
    }
  });

  return result;
};

/** 重建樹 */
export const reconstructTree = <T extends NestModel<T>>(input: T[]): T[] => {
  const cache: T[] = [...input];

  let index = 0;
  while (index !== cache.length) {
    const item = cache[index];
    if (item?.Parent && item.Parent.InverseParent.every((x) => cache.includes(x))) {
      item.Parent.InverseParent.forEach((x) => cache.splice(cache.indexOf(x), 1));
      cache.push(item.Parent);
      index = 0;
    }
    index += 1;
  }

  return cache;
};

/**
 * 範本填充工具，從 nodejs-tmpl 借來的，範例：
 * tmpl('answer is {a}', { a: 42 }) === 'the answer is 42'
 * tmpl('{0}: {1}', ['page', '2']) === 'page: 2'
 */
export const tmpl = (str: string, args: string[] | { [key: string]: string }): string =>
  str.replace(/{([^{]+?)}/g, (_, code: number | string) => {
    const value = Array.isArray(args) ? args[code as number] : args[code];
    return typeof value === 'undefined' ? `{${code}}` : value;
  });

/** UTC 時間格式化 */
export const formatTime = (time: Date, format = 'yyyy-MM-dd HH:mm:ss'): string =>
  DateTime.fromJSDate(time).setZone('UTC').toFormat(format);

/** 巢狀地區排序 */
export const sortLocation = (locations: LocationSet[]) => {
  const array = sortBy(locations, ['Name']);

  array.forEach((x) => {
    // eslint-disable-next-line no-param-reassign
    if (x.InverseParent.length) x.InverseParent = sortLocation(x.InverseParent);
  });

  return array;
};

/** 取得過濾後的地區資料 (註: 會改變原資料，請小心使用) */
export const locationFilter = (input: LocationSet, types: string[]): boolean => {
  if (types.includes(input.LocationTypeCode)) {
    // eslint-disable-next-line no-param-reassign
    input.InverseParent = [];
    return true;
  }

  if (input.InverseParent) {
    // eslint-disable-next-line no-param-reassign
    input.InverseParent = input.InverseParent.filter((x) => locationFilter(x, types));
    return !!input.InverseParent.length;
  }

  return false;
};

/** 取得過濾後的地區資料 By 遊戲類型 (註: 會改變原資料，請小心使用) */
export const locationFilterByGameType = (input: LocationSet, gameTypes: string[]): boolean => {
  if (gameTypes.some((gameType) => input.Code.includes(gameType))) {
    input.InverseParent = [];
    return true;
  }

  if (input.InverseParent) {
    input.InverseParent = input.InverseParent.filter((x) => locationFilterByGameType(x, gameTypes));
    return !!input.InverseParent.length;
  }

  return false;
};

/** 取得過濾後的錯誤類型 (註: 會改變原資料，請小心使用) */
export const errorExclusionFilter = (input: ErrorExclusionSet, types: string[]): boolean => {
  if (input.ErrorExclusionTypeCode && types.includes(input.ErrorExclusionTypeCode)) {
    // eslint-disable-next-line no-param-reassign
    input.InverseParent = [];
    return true;
  }

  if (input.InverseParent) {
    // eslint-disable-next-line no-param-reassign
    input.InverseParent = input.InverseParent.filter((x) => errorExclusionFilter(x, types));
    return !!input.InverseParent.length;
  }

  return false;
};

/** Base 64 轉換 by Dahis */
export const fileToDataUrl = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/** 試圖解決 Query string 轉換成物件時可能會變成單一元素陣列的問題 by Dahis */
export const breakUpSingleArray = <T>(arrayLikeOrNot: T[] | T) => {
  if (Array.isArray(arrayLikeOrNot)) {
    if (arrayLikeOrNot.length !== 1) throw new Error('陣列長度必須為 1');
    return arrayLikeOrNot[0];
  }
  return arrayLikeOrNot;
};

/** 完全空的 vue 元件 */
export const emptyComponent = { render: () => h('div') };

/** 從陣列中隨機選取一個 */
export const randomPick = <T>(array: Array<T>): T => array[Math.floor(Math.random() * array.length)];

/** 以 FlagMap 為基準對齊語系資料 */
export interface DetailModel {
  LangType: string;
  Contents: string;
  Title?: string;
}

export const detailLangTypeInit = <T extends DetailModel>(
  langType: { [index: string]: string },
  details: T[],
  userLocale: string,
  hasTitle = false
) => {
  const detailsClone = cloneDeep(details);
  const langTypes = Object.values(langType);
  const arr: T[] = [];

  langTypes.forEach((value) => {
    const defaultDetail = hasTitle ? { LangType: value, Contents: '', Title: '' } : { LangType: value, Contents: '' };

    const index = detailsClone.findIndex((x) => x.LangType === value);
    const payload = index === -1 ? (defaultDetail as T) : detailsClone[index];

    arr.push(payload);
  });

  arr.sort((a) => (a.LangType === userLocale ? -1 : 0));
  return arr;
};

/** base64 轉換檔案物件 */
export const urltoFile = async (url: string, fileName: string, type?: string) => {
  const mimeType = type || (url.match(/^data:([^;]+);/) || '')[1];
  const data = url.split(',');
  const byteCharacters = window.atob(data[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new File([byteArray], fileName, { type: mimeType });
};

/** Obj To FormData */
export const toFormData = (obj: any, acceptKeys: string[] = []) => {
  const formData = new FormData();
  const keys = Object.keys(obj);

  keys.forEach((key) => {
    if (!acceptKeys.length || acceptKeys.includes(key)) {
      formData.append(key, obj[key]);
    }
  });

  return formData;
};

/** 將 SystemFile 轉為 SLightBox 格式 */
export const lightBoxItemFormat = (files: SystemFileSet[]) => {
  return files.map((file) => ({
    src: `${import.meta.env.VITE_FILE_SERVER}${file.FilePath}`,
    type: file.FileType
  }));
};

/** 計算各式字元長度並切割至指定長度 */
export const stringWidthSlice = (str: string, length: number, suffix = '') => {
  let stringLength = 0;
  let result = '';

  const strArray = str.split('');

  strArray.forEach((char) => {
    const charLength = stringWidth(char);

    if (stringLength + charLength <= length) {
      stringLength += charLength;
      result += char;
    }
  });

  return str.length > length ? result + suffix : str;
};

/** 根據地區陣列找出指定類型的上層地區物件 */
export const findParentByLocationId = (
  locationId: string | undefined,
  locationArray: LocationSet[],
  acceptTypes: string[]
): LocationSet | undefined => {
  const target = locationArray.find((location) => location.Id === locationId);

  if (!target) return undefined;

  return acceptTypes.includes(target.LocationTypeCode)
    ? target
    : findParentByLocationId(target.ParentId, locationArray, acceptTypes);
};

/** 判斷日期1是否早於日期2 */
export const dateIsBefore = (date1: Date, date2: Date) => +date1 < +date2;

/** 地區範本文字 (供維護、公告範本使用) */
export const locationTemplateText = (locationFlat: LocationSet[], selectedTable: string[], allowTypes: string[]) => {
  // 先求出所有桌別的廳別
  const studios: { studioId: string | undefined; studioName: string; tables: (string | undefined)[] }[] = [];

  selectedTable.forEach((tableId) => {
    // 先求出該桌的廳別
    const studio = findParentByLocationId(tableId, locationFlat, allowTypes);

    // 取得此桌名稱
    const tableName = locationFlat.find((location) => location.Id === tableId)?.Name;

    if (studio) {
      // 確認陣列中是否已經有該廳別
      const existStudio = studios.find((s) => s.studioId === studio.Id);

      if (existStudio) existStudio.tables.push(tableName);
      else studios.push({ studioId: studio.Id, studioName: studio.Name, tables: [tableName] });
    }
  });

  return `【${studios.map((s) => `${s.studioName}- (${s.tables.join(', ')})`).join('、')}】`;
};

/** 查看權限表是否有特定頁面權限 */
export const checkPermission = (code: string | undefined, authTypes: string[], permisiionList: SiderbarItem[]) => {
  const hasPermission = permisiionList.some((item) => {
    if (item.Code === code) {
      const accessibleAuthCode = item.AuthMaps.map((authType) => authType.AuthCode);
      return authTypes.every((authType) => accessibleAuthCode.includes(Number(authType)))
        ? true
        : checkPermission(code, authTypes, item.SubMenu);
    }

    return checkPermission(code, authTypes, item.SubMenu);
  });

  return hasPermission;
};

/**
 * 根據 RoleTypeCode 回傳擁有的權限陣列
 * 例如 roleTypeCodeFormat(RoleType ,6) 回傳 [2, 4]
 * @param roleTypes Flagmap > RoleType
 * @param roleTypeCode RoleTypeCode
 */
export const roleTypeCodeFormat = (roleTypes: Record<string, string>, roleTypeCode: number) => {
  const roleTypeValues = sortBy(Object.values(roleTypes).map((value) => Number(value)));
  return roleTypeValues.filter((_, index) => roleTypeCode & (1 << index));
};

/**
 * 根據 RoleTypeCode 檢查是否有特定的裝置權限
 * 例如想知道 12 有沒有包含洗牌員的權限 => checkRolePermission(12, [RoleType.SHUFFLER])
 * @param roleTypeCode 加總後的 RoleTypeCode
 * @param roleTypes Flagmap > RoleType > RoleType Value
 */
export const checkRolePermission = (roleTypeCode: number, roleTypes: (string | number)[]) => {
  return roleTypes.every((roleType) => roleTypeCode & Number(roleType));
};

/**
 * 事件報告 - 系統建立的事件報告標題語系格式化
 * 若為系統建立的事件報告則將標題對應語系解析回傳
 */
export const eventRecordTitleFormatter = (
  eventRecordSet: EventRecordSet,
  f: <T>(flagmapType: string, value: T) => string
) => {
  const [eventType, eventTitle] = eventRecordSet.Title.split('-');

  const langTitle = eventTitle
    ? `${f('EventRecordAutoEnums', eventType)} - ${eventTitle}`
    : f('EventRecordAutoEnums', eventType);

  return eventRecordSet.IsSystemGenerated ? langTitle : eventRecordSet.Title;
};

/**
 * 驗證兩個日期之間差距有幾天
 * @param date1
 * @param date2
 */
export const getDaysDiff = (date1: Date, date2: Date) => {
  const start = DateTime.fromJSDate(date1);
  const end = DateTime.fromJSDate(date2);

  const diff = end.diff(start, 'days').toObject().days;

  return diff ? Math.round(Math.abs(diff)) : 0;
};

/**
 * 驗證是否可以解析 JSON
 */
export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

/**
 * 將地區底下的子層地區 ID 攤平
 */
export const getAllFlattenLocationId = (location: LocationSet): string[] => {
  return location.InverseParent.length
    ? location.InverseParent.flatMap((child) => getAllFlattenLocationId(child))
    : [location.Id ?? ''];
};
