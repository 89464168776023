/* 外部方法 */
import { defineStore, storeToRefs } from 'pinia';
import { v4 } from 'uuid';

/* 內部方法 */
import { isDevelopment } from '@sms/common/utils/helper';
import store from '@/store';
import useI18n from '@sms/common/composables/useI18n';

/* API */
import authService from '@/api/ajax/authService';

/* 型別 */
import MemberSet from '@sms/common/models/MemberSet';
import type SiderbarItem from '@sms/common/interfaces/SiderbarItem';

// store
export default defineStore('root', {
  state: () => ({
    machineId: window.localStorage.getItem('machineId') || 'Unknown',
    siderbar: JSON.parse(window.localStorage.getItem('siderbar') || '[]') as SiderbarItem[],
    fullSiderbar: JSON.parse(window.localStorage.getItem('fullSiderbar') || '[]') as SiderbarItem[],
    user: JSON.parse(window.localStorage.getItem('user') || '{}') as MemberSet,
    locale: window.localStorage.getItem('locale') || 'en',
    token: window.localStorage.getItem('token') || '',
    scale: window.devicePixelRatio,
    routeLoading: false
  }),

  actions: {
    setToken(newToken: string) {
      this.token = newToken;
      window.localStorage.setItem('token', newToken);
    },

    setUser(user: MemberSet) {
      this.user = user;
      window.localStorage.setItem('user', JSON.stringify(user));
    },

    setSiderbar(siderbar: SiderbarItem[]) {
      this.siderbar = siderbar;
      window.localStorage.setItem('siderbar', JSON.stringify(siderbar));
    },

    setFullSiderbar(siderbar: SiderbarItem[]) {
      this.fullSiderbar = siderbar;
      window.localStorage.setItem('fullSiderbar', JSON.stringify(siderbar));
    },

    setLocale(locale: string) {
      this.locale = locale;
      window.localStorage.setItem('locale', locale);
    },

    setRouteLoading(bool: boolean) {
      this.routeLoading = bool;
    },

    // 清除登入資訊
    clearAuth() {
      this.setToken('');
      this.setSiderbar([]);
      this.setFullSiderbar([]);
      this.setUser(new MemberSet());
    },

    // 更新使用者資訊
    async updateUser() {
      try {
        if (!this.token) return;

        const resp = await authService.info();
        this.setUser(resp.data.Data);
      } catch (error) {
        if (isDevelopment) console.error(error);
      }
    },

    setMachineId() {
      if (window.localStorage.getItem('machineId')) return;
      const id = v4();
      window.localStorage.setItem('machineId', id);
      this.machineId = id;
    },

    async initAPP() {
      const socketStore = store.useSocketStore();
      const { socket } = socketStore;
      const { isSocketConnected } = storeToRefs(socketStore);
      const { setLocale } = useI18n();

      setLocale(this.locale);

      if (!isSocketConnected.value) {
        await socket
          .build({ domain: import.meta.env.VITE_ADMIN_SIGNALR_SERVER, route: 'BackendHub' }, this.token, {
            loggerSite: 'admin'
          })
          .start();
      }
    }
  }
});
