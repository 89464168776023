<template>
  <div :class="rootClass" v-bind="inheritAttrs.attrsWithoutClass">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" />
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
/* 外部方法 */
import { computed, useAttrs } from 'vue';
import { twMerge } from 'tailwind-merge';

/* 樣式相關 */

// class 從繼承屬性中排除
const inheritAttrs = computed(() => {
  const { class: attrsClass, ...attrsWithoutClass } = useAttrs();

  return {
    attrsClass,
    attrsWithoutClass
  };
});

const rootClass = computed(() => {
  const defaultClass = 'circular w-16 h-16';
  return twMerge(defaultClass, inheritAttrs.value.attrsClass as string);
});
</script>

<style lang="scss" scoped>
.circular {
  svg {
    animation: spinner-svg-spinner 1.5s linear infinite;
    overflow: hidden;
    vertical-align: middle;
  }

  circle {
    animation: spinner-svg-progress 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    stroke: currentColor;
    fill: none;
    stroke-linecap: round;
    stroke-width: 5;
  }
}

@keyframes spinner-svg-spinner {
  0% {
    transform: rotate(-110deg);
  }

  20% {
    transform: rotate(-70deg);
  }

  60% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(250deg);
  }
}

@keyframes spinner-svg-progress {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  20% {
    stroke-dasharray: 1, 150;
    stroke-dash-offset: 0;
  }

  60% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>

<style>
.s-loading__relative {
  position: relative !important;
}

.s-loading__overflowY {
  overflow-y: hidden !important;
}
</style>
