/* 外部方法 */
import { defineStore } from 'pinia';

/* 內部方法 */
import useFlagMapStore from './flagMap';

/* 型別 */
import type NewsSet from '../models/NewsSet';
import type SocketPackageModel from '../interfaces/SocketPackageModel';
import type NewsSetDetails from '../models/NewsSetDetails';

// store
export default defineStore('news', {
  state: () => ({
    newsList: [] as NewsSet[]
  }),

  getters: {
    // 返回攤平後的語系內容並過濾使用者語系
    newsContentFlatten: (state) => {
      return (userLang: string) => {
        const newsMap = state.newsList.map((news) => {
          const userLangNews = news.NewsSetDetails.find((detail) => detail.LangType === userLang);
          return { ...userLangNews, NewsTypeCode: news.NewsTypeCode };
        });

        return newsMap.filter((detail): detail is NewsSetDetails => !!detail);
      };
    },

    normalNewsContentFlatten() {
      const {
        flagMapGroupList: { NewsType }
      } = useFlagMapStore();

      return (userLang: string) =>
        this.newsContentFlatten(userLang).filter(({ NewsTypeCode }) => NewsTypeCode === NewsType.Normal);
    },

    emergencyNewsContentFlatten() {
      const {
        flagMapGroupList: { NewsType }
      } = useFlagMapStore();

      return (userLang: string) =>
        this.newsContentFlatten(userLang).filter(({ NewsTypeCode }) => NewsTypeCode === NewsType.Temporary);
    }
  },

  actions: {
    // 公告排序由後端排序；先排序公告類型，緊急公告優先，再根據startTime由小排到大 (#2128)
    async onNewsNotify(serverNewsListData: SocketPackageModel<NewsSet[]>) {
      this.newsList = serverNewsListData.Data;
    }
  }
});
