/// <reference types="w3c-web-hid" />

/* 外部方法 */
import { ref } from 'vue';
import mitt from 'mitt';
import { validate } from 'uuid';
import { noop } from 'lodash-es';
import { useTimeoutFn } from '@vueuse/core';

/* 內部方法 */
import { isDevelopment } from '../utils/helper';

export enum ScanPrefix {
  ITEM = '&',
  MEMBER = '#',
  LOCATION = '@',
  CARD = '$',
  RACK = '!',
  SPECIALITEM = '&&',
  ASSISTCARD = '?'
}

export type EventTypes = {
  ITEM: string;
  MEMBER: string;
  LOCATION: string;
  UNKNOWN: string | undefined;
  CARD: [number, string];
  RACKMEMBER: string;
  RACKITEM: string;
  RACKLOCATION: string;
  SPECIALITEM: string;
  ASSISTCARD: undefined;
};

export interface ScanResult {
  type: keyof Omit<EventTypes, 'CARD'>;
  data: EventTypes[keyof Omit<EventTypes, 'CARD'>];
}

const event = mitt<EventTypes>();
if (isDevelopment) {
  event.on('*', console.log);
}
const parse = (str: string): ScanResult => {
  const result = str.match(/(\W{1,})([\w|-].*)?/) || [];
  const [, prefix, data] = result;

  if (validate(data)) {
    switch (prefix) {
      case ScanPrefix.ITEM:
        return { type: 'ITEM', data };
      case ScanPrefix.LOCATION:
        return { type: 'LOCATION', data };
      case ScanPrefix.MEMBER:
        return { type: 'MEMBER', data };
      case ScanPrefix.SPECIALITEM:
        return { type: 'SPECIALITEM', data };
      default:
        break;
    }
  }

  if (prefix === ScanPrefix.ASSISTCARD) {
    return { type: 'ASSISTCARD', data };
  }

  return { type: 'UNKNOWN', data: undefined };
};

let str = '';

// 輸入逾時的時候驗證輸入結果
const keyboardTimer = useTimeoutFn(
  () => {
    const { type, data } = parse(str);
    str = '';

    if (type === 'UNKNOWN' || data === undefined) return;
    event.emit(type, data);
  },
  // 應該很難有人可以在間隔 100 毫秒內用鍵盤打 UUID
  100,
  { immediate: false }
);

let isScannerKeydown = false;
let prevKeydownTimestamp = +new Date();

const keyboardHandler = (e: KeyboardEvent) => {
  const nextKeydownTimestamp = +new Date();
  isScannerKeydown = nextKeydownTimestamp - prevKeydownTimestamp < 20;
  prevKeydownTimestamp = nextKeydownTimestamp;

  // 避免吃到滑鼠點擊
  if (!e.key) return;
  // 避免吃到 Shift, F12 這類的鍵
  if (e.key.length !== 1) return;

  keyboardTimer.stop();

  str += e.key;
  if (isScannerKeydown) keyboardTimer.start();
};

/**
 * 鍵盤掃描模式
 */
const enableKeyboardMode = () => {
  // 避免重複監聽
  window.removeEventListener('keydown', keyboardHandler);

  window.addEventListener('keydown', keyboardHandler);
};

const bufferDecoder = new TextDecoder('utf-8');

const cardExgex = /^\$(\d{2})(.*)$/;
const rackExgex = /^!([RYGEDN]{1})(.*)$/;

const getBarcodeType = (input: string) => {
  if (/\]A[013457]/.test(input)) return 'Code 39';
  if (/\]Q[0-6]/.test(input)) return 'QR Code';

  return `unknown(${input})`;
};

/**
 * hid 輸入資料解析：
 * ```plain
 * byte 0:   內容長度
 * byte 1-3: 條碼格式，請見 honeywell 說明書，附錄 A-1
 * byte 4-n: 內文
 * ```
 * @param e  hid 輸入事件
 */
const hidParser = (e: HIDInputReportEvent) => {
  const hidData = e.data;
  const contentLength = hidData.getUint8(0);
  const barcodeType = getBarcodeType(bufferDecoder.decode(hidData.buffer.slice(1, 4)));
  const content = bufferDecoder.decode(hidData.buffer.slice(4, contentLength + 4));

  if (isDevelopment) {
    console.info(`[BARCODE][${barcodeType}]: ${content}`);
  }

  // 如果是撲克牌
  const [, scanner, card] = cardExgex.exec(content) || [];
  if (scanner) {
    event.emit('CARD', [Number(scanner), card]);
    return;
  }
  // 是牌櫃
  const [, rack, item] = rackExgex.exec(content) || [];
  if (rack) {
    const { type, data: guid } = parse(item);
    switch (type) {
      case 'ITEM':
        event.emit('RACKITEM', JSON.stringify({ rack, guid }));
        break;
      case 'MEMBER':
        event.emit('RACKMEMBER', JSON.stringify({ rack, guid }));
        break;
      case 'LOCATION':
        event.emit('RACKLOCATION', JSON.stringify({ rack, guid }));
        break;
      default:
        event.emit('UNKNOWN', JSON.stringify({ rack, guid }));
        break;
    }

    return;
  }

  // 如果是 QRCode 、 協助卡
  const { type, data } = parse(content);
  event.emit(type, data);
};

const hidScanners = ref<HIDDevice[]>([]);

/** 掃描器設定 */
const HIDUnavailableError = class extends Error {
  name = 'HIDUnavailableError';

  message = 'HID is unavailable';
};

const refreshHidDevices = async () => {
  if (!navigator.hid) {
    throw new HIDUnavailableError();
  }

  const devices = await navigator.hid.getDevices();

  /* eslint-disable no-param-reassign */
  devices.forEach((x) => {
    // 用 noop 把 buffer 清空
    x.addEventListener('inputreport', noop);

    // 等一秒讓掃牌邏輯加上去
    setTimeout(() => {
      x.addEventListener('inputreport', hidParser);
    }, 1000);

    // 打開掃描器
    if (!x.opened) {
      x.open();
    }
  });
  /* eslint-enable no-param-reassign */

  hidScanners.value = devices;
};

const requestHidDevice = async () => {
  if (!navigator.hid) {
    throw new HIDUnavailableError();
  }

  const [device] = await navigator.hid.requestDevice({ filters: [] });

  if (device && !device.opened) {
    await device.open();
  }

  refreshHidDevices();
};

export default function useScannerParser() {
  if (navigator.hid) {
    refreshHidDevices();
    navigator.hid.onconnect = refreshHidDevices;
    navigator.hid.ondisconnect = refreshHidDevices;
  }

  return {
    requestHidDevice,
    hidScanners,
    scannerEvent: event,
    parse,
    enableKeyboardMode
  };
}
