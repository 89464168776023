/* 外部方法 */
import { defineStore } from 'pinia';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash-es';
import store, { pinia } from '@/store';

/* 內部方法 */
import { isDevelopment } from '@sms/common/utils/helper';

/* API */
import approvalService from '@/api/ajax/approvalService';

/* 型別 */
import type ApprovalProcedure from '@sms/common/models/ApprovalProcedure';
import type Approval from '@sms/common/models/Approval';
import type ApprovalLocationSet from '@sms/common/models/ApprovalLocationSet';

export interface ApprovalTableItem extends ApprovalProcedure {
  RequestTime?: Date;
  RequestRest?: string;
  DeadlineDatetime: number | null;
  ActionCode: string;
  AfterData: any;
  BeforeData: any;
  ApprovalLocationSets: ApprovalLocationSet[];
}

export default defineStore('approval', {
  state: () => ({
    approvalList: [] as Approval[],
    approvalFlat: [] as ApprovalTableItem[]
  }),

  getters: {
    approvalFlatFilter(state) {
      const filter = state.approvalFlat.filter((x) => x.DeadlineDatetime === null || x.DeadlineDatetime > 0);
      return sortBy(filter, ['DeadlineDatetime']);
    }
  },

  actions: {
    async fetchApprovalList() {
      const rootStore = store.useRootStore(pinia);

      try {
        if (!rootStore.user.MemberSetLocationSets || !rootStore.user.MemberSetRoleSets) return;

        const payload = {
          LocationList: rootStore.user.MemberSetLocationSets?.map((x) => x.LocationSetId),
          RoleList: rootStore.user.MemberSetRoleSets?.map((x) => x.RoleSetId)
        };

        const resp = await approvalService.getMemberApproval(payload);
        const { Data } = resp.data;

        // 存一份原始的資料
        this.approvalList = Data;

        // 攤平簽核資料，並將主簽核表的部分屬性移到攤平後的子簽核表 (資料嫁接)
        // 避免重複覆蓋相同的資料造成倒數計時功能的畫面問題，會比對前後資料差異才更新 approvalFlat
        const dataFlat: ApprovalTableItem[] = Data.flatMap((mainApproval) => {
          const proceduresMap = mainApproval.ApprovalProcedures.map((approvalProcedures) => ({
            ...approvalProcedures,
            RequestTime: mainApproval.RequestTime,
            DeadlineDatetime: mainApproval.DeadlineDatetime
              ? Math.round(DateTime.fromJSDate(new Date(mainApproval.DeadlineDatetime)).diffNow('seconds').seconds)
              : null,
            MemberName: mainApproval.MemberName,
            ActionCode: mainApproval.ApprovalSetting.ActionCode,
            BeforeData: mainApproval.BeforeData && JSON.parse(mainApproval.BeforeData),
            AfterData: mainApproval.AfterData && JSON.parse(mainApproval.AfterData),
            RequestRest: mainApproval.RequestRest,
            ApprovalLocationSets: mainApproval.ApprovalLocationSets,
            Memo: mainApproval.Memo
          }));

          return proceduresMap;
        });

        // 先把不存在的資料移除
        this.approvalFlat.forEach((x, index) => {
          const idx = dataFlat.findIndex((o) => o.Id === x.Id);
          if (idx === -1) this.approvalFlat.splice(index, 1);
        });

        // 把新增的資料加進陣列
        dataFlat.forEach((x) => {
          const idx = this.approvalFlat.findIndex((o) => o.Id === x.Id);
          if (idx === -1) this.approvalFlat.push(x);
        });
      } catch (error) {
        if (isDevelopment) console.error(error);
      }
    },

    countdown() {
      this.approvalFlat.forEach((x) => {
        if (x.DeadlineDatetime && x.DeadlineDatetime > 0) {
          x.DeadlineDatetime -= 1;
        }
      });
    }
  }
});
