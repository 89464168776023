/* 外部方法 */
import { computed, watch } from 'vue';

/* 內部方法 */
import useI18n from './useI18n';

export default function useFontFamily() {
  const { i18n } = useI18n();

  const notoFonts = [
    { lang: 'zh-TW', font: 'Noto Sans TC' },
    { lang: 'zh-CN', font: 'Noto Sans SC' }
  ];

  const fontFamily = computed(() => {
    const lang = i18n.global.locale.value;
    const currentFont = notoFonts.find((x) => x.lang === lang);
    const fontFilter = notoFonts.filter((x) => x.lang !== currentFont?.lang);
    const fontFamilys = currentFont ? [currentFont, ...fontFilter] : fontFilter;

    return `Noto Sans, ${fontFamilys.map((x) => x.font).join(', ')}, sans-serif`;
  });

  const setFontFamily = (style: string) => {
    document.body.style.fontFamily = style;
  };

  watch(fontFamily, setFontFamily, { immediate: true });
}
