<template>
  <teleport to="body" :disabled="teleportDisabled">
    <transition
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      enter-active-class="ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      leave-active-class="ease-in duration-200"
    >
      <div v-if="modelValue" ref="containerRef" class="fixed inset-0 overflow-y-auto">
        <div class="flex items-center justify-center h-full text-center">
          <transition
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            enter-active-class="ease-out duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            leave-active-class="ease-in duration-200"
          >
            <div v-show="modelValue" class="fixed inset-0 transition-opacity">
              <div class="absolute inset-0 bg-gray-400 opacity-75" />
            </div>
          </transition>

          <transition
            enter-active-class="duration-300 ease-out"
            enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            enter-to-class="translate-y-0 opacity-100 sm:scale-100"
            leave-active-class="duration-200 ease-in"
            leave-class="translate-y-0 opacity-100 sm:scale-100"
            leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <div v-show="modelValue" class="flex flex-col items-center justify-center z-10">
              <SProgressCircular :class="loadingColor" />
              <div v-if="loadingText" v-text="loadingText" :class="[loadingColor, 'mt-4 text-sm']" />
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { ref, watch, nextTick } from 'vue';

/* 內部方法 */
import nextZIndex from '../SModal/utils';

/* 內部組件 */
import SProgressCircular from '../SProgressCircular/SProgressCircular.vue';

interface Props {
  // 資料相關
  modelValue?: boolean;
  teleportDisabled?: boolean;

  // 畫面相關
  loadingText?: string;
  loadingColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
  // 資料相關
  modelValue: false,
  teleportDisabled: false,

  // 畫面相關
  loadingText: '',
  loadingColor: 'text-white'
});

/** z-index 控制 */
const containerRef = ref<HTMLElement | null>(null);

watch(
  () => props.modelValue,
  async (val) => {
    if (!val) return;

    await nextTick();
    (containerRef.value as HTMLElement).style.zIndex = `${nextZIndex()}`;
  },
  { immediate: true }
);
</script>
