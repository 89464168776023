/* 外部方法 */
import { reactive, toRefs } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

/** Option Defaults */
const defaults = {
  text: '',
  color: 'text-white'
};

/** 全域 Loading 狀態控制 */
const loading = reactive({
  visible: false,
  text: '',
  color: ''
});

export default function useLoading() {
  const show = (options = {} as Partial<typeof defaults>) => {
    if (loading.visible) return;

    const { text, color } = { ...defaults, ...options };

    loading.text = text;
    loading.color = color;
    loading.visible = true;
  };

  const hide = () => {
    loading.visible = false;
  };

  try {
    onBeforeRouteLeave(hide);
  } catch {
    //
  }

  return {
    ...toRefs(loading),
    show,
    hide
  };
}
