<template>
  <Teleport to="body">
    <div class="fixed inset-0 z-[100] flex flex-col justify-start overflow-hidden gap-y-3 p-5 pointer-events-none">
      <TransitionGroup name="fade">
        <component
          v-for="message in messages"
          :key="message.id"
          :is="getTemplate(message.template)"
          @close="($event: string) => deleteMessage($event)"
          v-bind="message"
        ></component>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
/* 內部方法 */
import useSMessage from './../composables/useSMessage';

/* 內部組件 */
import SMessageTemplateDefault from './templates/SMessageTemplateDefault.vue';

const { messages, deleteMessage } = useSMessage();

const getTemplate = (template: string | undefined) => {
  switch (template) {
    case 'default':
      return SMessageTemplateDefault;
    default:
      return SMessageTemplateDefault;
  }
};
</script>

<style scoped>
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.fade-leave-active {
  position: absolute;
}
</style>
