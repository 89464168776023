/* 型別 */
import type CustomerSet from './CustomerSet';
import type LoginHistorySet from './LoginHistorySet';
import type MemberSetRoleSet from './MemberSetRoleSet';
import type MemberSetLocationSet from './MemberSetLocationSet';

export default class MemberSet {
  Id? = '';

  Account = '';

  Password = '';

  Status = false;

  TwoFactorAuth = '';

  CreateMemberId? = '';

  UpdateMemberId? = '';

  DeskId? = '';

  LangTypeCode = '';

  Avatar = '';

  StudioPhoto = '';

  Name = '';

  Nickname = '';

  Gender = 2;

  RoleTypeCode = 0;

  Height?: number | null = null;

  Weight?: number | null = null;

  Bwh = '';

  PhoneNumber = '';

  Email = '';

  Address = '';

  EmergencyContact = '';

  ForeignId = '';

  IsDelete = false;

  Birthday?: Date | null = null;

  ArrivedDate?: Date | null = null;

  IsInitPassword = false;

  CreateDatetime = new Date();

  UpdateDatetime = new Date();

  CreateMember = {} as MemberSet;

  UpdateMember? = {} as MemberSet;

  CustomerSets?: CustomerSet[] = [];

  LoginHistorySets?: LoginHistorySet[] = [];

  MemberSetLocationSets?: MemberSetLocationSet[] = [];

  MemberSetRoleSets?: MemberSetRoleSet[] = [];
}
