/* 外部方法 */
import { createPinia } from 'pinia';

/* 內部方法 */
import useRootStore from '@/store/modules/root';
import useModalStore from '@/store/modules/modal';
import useApprovalStore from '@/store/modules/approval';
import useLocationStore from '@/store/modules/location';
import useNewsStore from '@sms/common/store/news';
import useFlagMapStore from '@sms/common/store/flagMap';
import useSocketStore from '@sms/common/store/socket';

/* 型別 */
import type { AdminListenEvents, AdminInvokeEvents } from '@/api/socket';

export const pinia = createPinia();

export default {
  useRootStore,
  useModalStore,
  useApprovalStore,
  useNewsStore,
  useLocationStore,
  useFlagMapStore,
  useSocketStore: useSocketStore<AdminListenEvents, AdminInvokeEvents>()
};
