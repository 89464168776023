/* 外部方法 */
import { createRouter, createWebHashHistory } from 'vue-router';

/* 內部方法 */
import store, { pinia } from '@/store';
import { addRoutes, hasPermission } from '@/utilities/routerHelper';

/* API */
import authService from '@/api/ajax/authService';

/* 型別 */
import type { RouteRecordRaw } from 'vue-router';

const rootStore = store.useRootStore(pinia);
const modalStore = store.useModalStore(pinia);
store.useLocationStore(pinia);

/**
 * 1. 要在後台新增新的頁面功能時，必須先去後端的 CoreCenter/Data/SeedData.json 新增相關的頁面，FeatureType 必須設為 4
 * 如此一來前端路由會自動建立三個相關路由，以 account 為例 ['/account', '/account/create', '/account/edit/:input']
 *
 * 2. 相關的權限驗證也會使用 SeedData.json 中填寫的 Uri 作為關鍵字驗證
 *
 * 3. 若有任何並非出現在左側 Sidebar 的擴充頁面想要新增，可以直接新增在 path: '/' 的 'children' 屬性內 (請勿新增在 404 路由後面)
 *
 * 4. 若該擴充頁面 (路由) 也需要要後端驗證權限，則在 meta 屬性中加上 Uri 以及 requirePermission 供驗證
 *
 * 例如想在 account 頁面中新增一個測試用的路由，但僅限擁有 'account' 權限的使用者可以訪問，則可以像這樣新增路由
 *
 * {
 *    path: '/account/test',
 *    name: 'account-test',
 *    component: () => import('組件路由'),
 *    meta: { requirePermission: true, Uri: 'account' }
 * }
 */

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue')
  },
  {
    path: '/',
    name: 'systemPages',
    redirect: '/dashboard',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '/change-password',
        name: 'change-password',
        component: () => import('@/views/pages/ChangePassword.vue')
      },
      {
        path: '/stuffClass/detail-list/:id',
        name: 'stuffList',
        component: () => import('@/views/stuffClass/StuffList.vue'),
        meta: { requirePermission: true, Uri: 'stuffClass' },
        props: true
      },
      {
        path: '/stuffOrder/detail-list/:orderCode',
        name: 'stuffOrderDetailList',
        component: () => import('@/views/stuffOrder/StuffOrderDetailList.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 進貨單新增/編輯
      {
        path: '/stuffOrder/receive/:id?',
        name: 'stuffOrderRecive',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderReceive.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 驗貨單新增/編輯
      {
        path: '/stuffOrder/inspection/:id?',
        name: 'stuffOrderInspection',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderInspection.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 領用單新增/編輯
      {
        path: '/stuffOrder/withdraw/:id?',
        name: 'stuffOrderWithdraw',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderWithdraw.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 異動單新增/編輯
      {
        path: '/stuffOrder/changeLocation/:id?',
        name: 'stuffOrderChangeLocation',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderChangeLocation.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 報修單新增/編輯
      {
        path: '/stuffOrder/repair/:id?',
        name: 'stuffOrderRepair',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderRepair.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 報廢單新增/編輯
      {
        path: '/stuffOrder/discard/:id?',
        name: 'stuffOrderDiscard',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderDiscard.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 驗退單編輯
      {
        path: '/stuffOrder/returned/:id?',
        name: 'stuffOrderReturned',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderReturned.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 入庫單編輯
      {
        path: '/stuffOrder/putaway/:id?',
        name: 'stuffOrderPutaway',
        component: () => import('@/views/stuffOrder/StuffOrders/StuffOrderPutaway.vue'),
        meta: { requirePermission: true, Uri: 'stuffOrder' },
        props: true
      },
      // 數量查詢 -> 分佈總覽
      {
        path: '/stock/distribution',
        name: 'stockDistribution',
        component: () => import('@/views/stock/DistributionIndex.vue'),
        meta: { requirePermission: true, Uri: 'stock' },
        props: true
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/pages/404.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// 路由注入
addRoutes(router, rootStore.siderbar);

router.beforeEach(async (to, from) => {
  try {
    rootStore.setRouteLoading(true);

    // 轉頁時自動清除對話框
    modalStore.clear();

    const resp = await authService.check();
    const authCheck = resp.data.Data;

    // Token 驗證不過返回登入頁
    if (to.name !== 'login' && to.name !== from.name && !authCheck) {
      return { name: 'login', query: { redirect: to.fullPath } };
    }

    // 透過非登出方式要去登入頁但 Token 還有效則返回儀表板
    if (to.name === 'login' && to.name !== from.name && authCheck) {
      return { name: 'dashboard' };
    }

    // 檢查要前往的頁面 Uri 是否有在後端返回的資料中，否則拒絕導航
    if (to.matched.some((record) => record.meta.requirePermission) && !hasPermission(to.meta.Uri, rootStore.siderbar)) {
      return false;
    }

    return true;
  } catch (error) {
    return to.name !== 'login' ? { name: 'login', query: { redirect: to.fullPath } } : true;
  } finally {
    rootStore.setRouteLoading(false);
  }
});

export default router;
