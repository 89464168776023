/* 外部方法 */
import axios from 'axios';

/** 型別 */
import type LogPayload from '../interfaces/LogPayload';

export default {
  postLog(logPayload: LogPayload) {
    return axios.post(`${import.meta.env.VITE_LOG_SERVER}LogRecorder/SMSFrontEndMultiRecord`, logPayload);
  }
};
