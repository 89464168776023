/* 外部方法 */
import { countBy } from 'lodash-es';
import SourceLoader from './SourceLoader';

/* 內部組件 */
import clubIcon from '../assets/svg/club.svg';
import diamondIcon from '../assets/svg/diamond.svg';
import heartIcon from '../assets/svg/heart.svg';
import spadeIcon from '../assets/svg/spade.svg';

export const pokerLoader = new SourceLoader(import.meta.glob('../components/Card/cards/*.svg', { eager: true }));

export const smallPokerLoader = new SourceLoader(
  import.meta.glob('../components/CardSmall/cards/*.svg', { eager: true })
);

export type PokerPoints = 'A' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | 'J' | 'Q' | 'K';

export enum PokerSuits {
  SPADE = 's',
  HEART = 'h',
  DIAMOND = 'd',
  CLUB = 'c'
}

export default class Poker {
  /** 點數 */
  point: string;

  /** 花色 */
  suit: string;

  /** 牌面 = 點數 + 花色 */
  face: string;

  constructor(face: string | null) {
    // 當傳入牌面時，解析撲克牌牌面
    if (face) {
      const reg = new RegExp(`(${Poker.points.join('|')})(${Poker.suits.join('|')})`);
      const parseResult = face.match(reg);

      // 撲克牌牌面含有不存在的字元或無法解析時拋出錯誤
      if (!parseResult) throw new Error(`${face} 不是正確的撲克牌牌面定義。`);

      [this.face, this.point, this.suit] = parseResult;
    } else {
      this.point = '-';
      this.suit = '-';
      this.face = this.point + this.suit;
    }
  }

  static parseFace(face: string | null) {
    if (!face) throw new Error();

    const reg = new RegExp(`(${Poker.points.join('|')})(${Poker.suits.join('|')})`);
    const parseResult = face.match(reg);

    // 撲克牌牌面含有不存在的字元或無法解析時拋出錯誤
    if (!parseResult) throw new Error(`${face} 不是正確的撲克牌牌面定義。`);

    const [, point, suit] = parseResult;

    return { point: point as PokerPoints, suit: suit as PokerSuits };
  }

  /** 點數
   * - **A**ce
   * - **J**ack
   * - **Q**ueen
   * - **K**ing
   */
  static points: PokerPoints[] = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

  /** 花色
   * - 紅心 - **h**eart
   * - 方塊 - **d**iamond
   * - 黑桃 - **s**pade
   * - 梅花 - **c**lub
   */
  static suits: PokerSuits[] = [PokerSuits.CLUB, PokerSuits.DIAMOND, PokerSuits.HEART, PokerSuits.SPADE];

  static getSuitIcon(suit: PokerSuits | string, location = window.location.origin) {
    switch (suit) {
      case PokerSuits.CLUB:
        return `${location}${clubIcon}`;

      case PokerSuits.HEART:
        return `${location}${heartIcon}`;

      case PokerSuits.DIAMOND:
        return `${location}${diamondIcon}`;

      case PokerSuits.SPADE:
        return `${location}${spadeIcon}`;

      default:
        return '';
    }
  }

  /** 取得一副牌 */
  static getADeck() {
    return this.points
      .flatMap((point: PokerPoints) => this.suits.map((suit) => point + suit))
      .map((face) => new Poker(face));
  }

  /** 統計花色數量 */
  static countSuit(pokerList: Poker[]) {
    return countBy(pokerList.map((poker) => poker.suit));
  }

  /** 統計點數數量 */
  static countPoint(pokerList: Poker[]) {
    return countBy(pokerList.map((poker) => poker.point));
  }

  /** 統計牌面數量 */
  static countFace(pokerList: Poker[]) {
    return countBy(pokerList.map((poker) => poker.face));
  }

  /** 驗證牌面字串是否正確 */
  static isValid(face: string) {
    return this.points.flatMap((point: PokerPoints) => this.suits.map((suit) => point + suit)).includes(face);
  }
}

/** 掃描器的卡片條碼與牌面對應 */
export const PokerBarcodes: { [index: string]: string } = {
  // 黑桃
  10105: 'As',
  10209: '2s',
  10313: '3s',
  10417: '4s',
  10521: '5s',
  10625: '6s',
  10729: '7s',
  10833: '8s',
  10937: '9s',
  11003: '10s',
  11107: 'Js',
  11211: 'Qs',
  11315: 'Ks',

  // 愛心
  20106: 'Ah',
  20210: '2h',
  20314: '3h',
  20418: '4h',
  20522: '5h',
  20626: '6h',
  20730: '7h',
  20834: '8h',
  20938: '9h',
  21004: '10h',
  21108: 'Jh',
  21212: 'Qh',
  21316: 'Kh',

  // 梅花
  30107: 'Ac',
  30211: '2c',
  30315: '3c',
  30419: '4c',
  30523: '5c',
  30627: '6c',
  30731: '7c',
  30835: '8c',
  30939: '9c',
  31005: '10c',
  31109: 'Jc',
  31213: 'Qc',
  31317: 'Kc',

  // 方塊
  40108: 'Ad',
  40212: '2d',
  40316: '3d',
  40420: '4d',
  40524: '5d',
  40628: '6d',
  40732: '7d',
  40836: '8d',
  40940: '9d',
  41006: '10d',
  41110: 'Jd',
  41214: 'Qd',
  41318: 'Kd',

  // 紅卡
  50005: 'red'
};
