/* 外部方法 */
import { onMounted, watchEffect } from 'vue';

/* 內部方法 */
import useI18n from './useI18n';

/** 按住鍵盤按鍵不動，1分鐘約能發出1800次事件，平均1秒30次，以此為標準設定上限為25次 */
const AVERAGE_ABNORMAL_PRESS_COUNT = 25;

/** 一次5秒檢查是否有異常次數的事件頻發 */
const CHECK_INTERVAL_SEC = 5;

/** 平均5秒內最多能發出事件總數 */
const LIMIT = AVERAGE_ABNORMAL_PRESS_COUNT * CHECK_INTERVAL_SEC;

/** 偵測鍵盤異常活動，如有持續按特定按鍵情況，跳出彈窗提示鍵盤故障 */
export default function useAbnormalKeypress(modalOk: (param: { title: string }) => Promise<unknown>) {
  const { t } = useI18n();

  let isStopCheck = false;
  let keyPressed: Record<string, number> = {};

  const onKeyPress = async (event: KeyboardEvent) => {
    if (isStopCheck) return;

    keyPressed[event.key] = keyPressed[event.key] + 1 || 1;

    if (keyPressed[event.key] < LIMIT) return;

    isStopCheck = true;
    keyPressed = {};
    await modalOk({ title: t('Error.AbnormalKeyPress') });
    isStopCheck = false;
  };

  onMounted(() => {
    watchEffect((onClearUp) => {
      window.addEventListener('keydown', onKeyPress);
      const timer = window.setInterval(() => {
        keyPressed = {};
      }, 1000 * CHECK_INTERVAL_SEC);

      onClearUp(() => {
        window.clearInterval(timer);
        window.removeEventListener('keydown', onKeyPress);
      });
    });
  });
}
