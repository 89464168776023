<template>
  <LoadingOverlay
    v-model:show="systemLoading.show"
    v-model:reload="systemLoading.reload"
    :api-callback="fetchSystemAPI"
    site="admin"
  />

  <router-view v-if="!systemLoading.show" />

  <SModal
    v-for="(modal, index) in modals"
    :key="index"
    :closable="false"
    :model-value="true"
    :options="modal"
    :content-class="modal.contentClass"
    @on-cancel="modal.onCancel && modal.onCancel()"
    @on-confirm="modal.onConfirm && modal.onConfirm()"
  />

  <SMessage />

  <SLoading
    v-model="loading.visible.value"
    :loading-text="loading.text.value"
    :loading-color="loading.color.value"
    teleport-disabled
  />
</template>

<script lang="ts" setup>
/* 外部方法 */
import { reactive, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

/* 內部方法 */
import store from '@/store';
import { initAxiosCustomErrorInterceptor } from '@/api/ajax/settings';
import { pokerLoader, smallPokerLoader } from '@sms/common/utils/poker';
import useI18n from '@sms/common/composables/useI18n';
import useLoading from '@sms/common/composables/useLoading';
import useModal from '@/composables/useModal';
import useFontFamily from '@sms/common/composables/useFontFamily';
import useScannerParser from '@sms/common/composables/useScannerParser';
import useDetectAbnormalKeypress from '@sms/common/composables/useAbnormalKeypress';

/* 內部組件 */
import SModal from '@sms/components/SModal/SModal.vue';
import SMessage from '@sms/components/SMessage/SMessage.vue';
import SLoading from '@sms/components/SLoading/SLoading.vue';
import LoadingOverlay from '@sms/common/components/LoadingOverlay/LoadingOverlay.vue';

/* API */
import flagMapService from '@/api/ajax/flagMapService';

/* 系統元件 */
const router = useRouter();
const loading = useLoading();
const { fetchTranslation } = useI18n();

/* Stores */
const flagMapStore = store.useFlagMapStore();
const modalStore = store.useModalStore();

/* Socket 相關 */
const { startUpdateSocketState, stopUpdateSocketState } = store.useSocketStore();

/* 全域 Modal 控制 */
const { modals } = storeToRefs(modalStore);

/* 字型控制 */
useFontFamily();

/* 啟用鍵盤 scanner 模式 */
const { enableKeyboardMode } = useScannerParser();
enableKeyboardMode();

/* 初始化 Ajax 請求的錯誤處理方式 */
initAxiosCustomErrorInterceptor(router, useModal);

/* 鍵盤異常、故障監測 */
const { modalOk } = useModal();
useDetectAbnormalKeypress(modalOk);

/**
 * 系統 Loading 相關
 * 進入系統前必須等待 `LoadingOverlay.vue` 將系統所需的 API 讀取完成
 */
const systemLoading = reactive({
  show: false,
  reload: false
});

const fetchSystemAPI = async () => {
  await Promise.all([
    fetchTranslation(),
    flagMapStore.fetchFlagMapData(flagMapService.getAll),
    pokerLoader.load(),
    smallPokerLoader.load()
  ]);
};

/* 更新 Socket 狀態 */
onMounted(startUpdateSocketState);
onBeforeUnmount(stopUpdateSocketState);
</script>
