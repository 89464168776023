/* 外部方法 */
import axios from 'axios';
import { setWith } from 'lodash-es';
import { createI18n } from 'vue-i18n';

/* 型別 */
import type ResponseBaseModel from '../interfaces/ResponseBaseModel';
import type TranslationSet from '../models/TranslationSet';

const i18n = createI18n<false>({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  missingWarn: false,
  fallbackWarn: false
});

interface Translation {
  [key: string]: string | Translation;
}

let retry = 0;
// FIXME: 這邊就處理 ajax 嗎？
/** 取得翻譯 */
const fetchTranslation = async (baseURL = '') => {
  const result: Translation = {};
  retry += 1;

  try {
    const resp = await axios.get<ResponseBaseModel<TranslationSet[]>>(`${baseURL}/api/Translation`);
    resp.data.Data.forEach((x) => {
      setWith(result, `${x.LangTypeCode}.${x.Code}`, x.Value);
    });

    Object.keys(result).forEach((x) => {
      i18n.global.setLocaleMessage(x, result[x] as Translation);
    });
  } catch (error) {
    // FIXME: 若無法取得翻譯，應停止流程
    if (retry > 5) throw new Error('');
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await fetchTranslation(baseURL);
  }
};

// 預設為英文
const setLocale = (locale = 'en') => {
  i18n.global.locale.value = locale;

  document.querySelector('html')?.setAttribute('lang', locale);
};

const { t } = i18n.global;

/** 翻譯 Flagmap */
const f = <T>(flagmapType: string, value: T) => t(`${flagmapType}.${value}`);

/** 翻譯 FlagGroup， 回傳 SSelectItem 相容的陣列 */
const fg = <T>(flagmapType: string, obj: Record<string, T>) =>
  Object.values(obj || {}).map((x) => ({
    text: f(flagmapType, x),
    value: x
  }));

export default function useI18n() {
  return {
    i18n,
    fetchTranslation,
    setLocale,
    t,
    f,
    fg
  };
}
