/* 外部方法 */
import { DateTime } from 'luxon';

/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';
import type Approval from '@sms/common/models/Approval';
import type { ISearchModel } from '@sms/common/composables/useSearch';
import type SearchResponseBaseModel from '@sms/common/interfaces/SearchResponseBaseModel';

export class ApprovalQueryModel {
  MemberName = '';

  ApprovalStatusCode = '';

  StartRequestTime: Date | null = DateTime.utc().minus({ months: 3 }).startOf('day').toJSDate();

  EndRequestTime: Date | null = DateTime.utc().plus({ months: 3 }).endOf('day').toJSDate();
}

export class UpdateApprovalProcedureStatusRequest {
  Id = '';

  ApprovalProcedureStatus = '0';

  Memo = '';
}

export class UpdateApprovalStatusRequest {
  Id = '';

  ApprovalStatus = '0';
}

export interface GetMemberApprovalRequest {
  LocationList: string[];
  RoleList: string[];
}

export default {
  search(searchModel: ISearchModel): AxiosPromise<SearchResponseBaseModel<Approval[]>> {
    return api.post('/Approval/Search', searchModel);
  },

  getMemberApproval(getMemberApprovalRequest: GetMemberApprovalRequest): AxiosPromise<ResponseBaseModel<Approval[]>> {
    return api.post('/Approval/GetMemberApproval', getMemberApprovalRequest);
  },

  updateApprovalProcedureStatus(
    updateApprovalProcedureStatusRequest: UpdateApprovalProcedureStatusRequest
  ): AxiosPromise<ResponseBaseModel<Approval>> {
    return api.post('/Approval/UpdateApprovalProcedure', updateApprovalProcedureStatusRequest);
  },

  updateApprovalStatus(
    updateApprovalStatusRequest: UpdateApprovalStatusRequest
  ): AxiosPromise<ResponseBaseModel<Approval>> {
    return api.post('/Approval/UpdateApprovalStatus', updateApprovalStatusRequest);
  }
};
