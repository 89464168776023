/* 外部方法 */
import { readonly, ref } from 'vue';
import { v4 } from 'uuid';

/* 型別 */
import type { SMessageOption } from './../SMessage/types';

interface SMessageOptionWithId extends SMessageOption {
  id: string;
}

interface DeleteMessageOptions {
  // 幾秒後刪除訊息，單位毫秒
  duration?: number;
  immediate?: boolean;
}

/** 全域 Message 控制 */
const _messages = ref<SMessageOptionWithId[]>([]);

export default function useSMessage() {
  const deleteMessage = (id: string, options: DeleteMessageOptions = {}) => {
    const { duration = 5000, immediate = false } = options;

    const deleteById = () => {
      const index = _messages.value.findIndex((message) => id === message.id);
      if (index !== -1) _messages.value.splice(index, 1);
    };

    if (immediate) {
      deleteById();
      return;
    }

    setTimeout(deleteById, duration);
  };

  const showMessage = (option: SMessageOption, duration = 5000) => {
    const uniqueId = v4();

    const message: SMessageOptionWithId = {
      id: uniqueId,
      template: 'default',
      type: 'warning',
      position: 'center',
      closable: false,
      ...option
    };

    _messages.value.push(message);

    deleteMessage(uniqueId, { duration });
  };

  const clearMessage = () => {
    _messages.value.length = 0;
  };

  return {
    messages: readonly(_messages),
    showMessage,
    clearMessage,
    deleteMessage: (id: string) => deleteMessage(id, { immediate: true })
  };
}
