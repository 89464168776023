/* 型別 */
import type SocketPackageModel from '../interfaces/SocketPackageModel';

export interface ParseJSONOptions {
  parseTime?: boolean;
}

// 全域定義
declare global {
  interface String {
    parseJSON<T>(options?: ParseJSONOptions): SocketPackageModel<T>;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.parseJSON = function parseJSON<T>(this: string, options: ParseJSONOptions = {}) {
  const { parseTime = true } = options;

  const parsedData: SocketPackageModel<T> = JSON.parse(this, (key, value) => {
    // !!value 可以避免意外地將 null 或 undefined 轉換為時間
    if (parseTime && !!value) {
      // 當後端回傳的欄位結尾為 Time 或 Datetime 則視為時間並進行轉換
      return /Time$/.test(key) || /Datetime$/.test(key) ? new Date(value) : value;
    }
    return value;
  });

  return parsedData;
};
