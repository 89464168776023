/* 外部方法 */
import { noop } from 'lodash-es';

/**
 * 用於避免瀏覽器分頁進入閒置狀態後導致 signalr 斷線的輔助函式
 * 使用方法 1. signalr 連線後執行 getLock() 2. signalr 斷線後執行 unlock()
 */
export default class SignalrLock {
  public unlock = noop;

  public getLock() {
    if (navigator && navigator.locks) {
      const promise = new Promise((resolve) => {
        this.unlock = resolve;
      });

      navigator.locks.request('signalr_lock', { mode: 'shared' }, () => promise);
    }
  }
}
