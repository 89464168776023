/* 外部方法 */
import { defineStore } from 'pinia';
import { groupBy, findKey } from 'lodash-es';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type FlagMap from '../models/FlagMap';
import type { FlagMapTypes } from '../models/FlagMap';
import type ResponseBaseModel from '../interfaces/ResponseBaseModel';

export type OriginalFlagMapGroupList = Record<FlagMapTypes, FlagMap[]>;
export type FlagMapGroupList = { [key in FlagMapTypes]: Record<string, string> };

export default defineStore('flagMap', {
  state: () => {
    try {
      const localFlagMaps = window.localStorage.getItem('flagMaps');
      const isLocalFlagMapsExist = localFlagMaps !== null && localFlagMaps !== 'undefined';
      const flagMapData = isLocalFlagMapsExist ? JSON.parse(localFlagMaps) : ([] as FlagMap[]);
      return { flagMapData };
    } catch (error) {
      console.error(error);
      return { flagMapData: [] };
    }
  },

  getters: {
    isFlagMapInitialized(state) {
      return !!state.flagMapData.length;
    },

    /**
     * 原始 FlagMap 根據 Name 群組化後的陣列
     *
     * 此陣列中的每個物件鍵為 FlagMap 的 Name，值為隸屬於該 Name 底下的所有 FlagMap
     *
     * */
    originalFlagMapGroupList(): OriginalFlagMapGroupList {
      return groupBy(this.flagMapData, 'Type') as OriginalFlagMapGroupList;
    },

    /**
     * 原始 FlagMap 根據 Name 群組化並簡化後的陣列
     *
     * 此陣列中的每個物件鍵為 FlagMap 的 Name，值為隸屬於該 Name 底下所有 FlagMap 的值
     *
     * @description 如在引用此 getter 時發現 TypeScript 報錯，請至 sms/common/models/FlagMap.ts 檔案中對 type FlagMapTypes 新增該鍵值
     * */
    flagMapGroupList(): FlagMapGroupList {
      return Object.fromEntries(
        Object.entries(groupBy(this.flagMapData, 'Type')).map(([key, value]) => [
          key,
          Object.fromEntries(
            value
              .sort(({ Seq: aSeq }, { Seq: bSeq }) => Number(aSeq) - Number(bSeq))
              .map(({ Name, Value }) => [Name, Value])
          )
        ])
      ) as { [key in FlagMapTypes]: Record<string, string> };
    }
  },

  actions: {
    async fetchFlagMapData(fetchFunc: () => AxiosPromise<ResponseBaseModel<FlagMap[]>>) {
      const res = await fetchFunc();
      this.flagMapData = res.data.Data;
      window.localStorage.setItem('flagMaps', JSON.stringify(res.data.Data));
    },

    /** 依類型取得 FlagMap 群組 */
    getFlagMapGroup(type: FlagMapTypes) {
      const flagMapType = this.flagMapGroupList[type];

      if (!flagMapType) throw new Error(`[FlagMap] Invalid FlagMap type: ${type}`);

      return flagMapType;
    },

    /** 依值 (value) 取得特定 FlagMap 群組中的屬性 (key) */
    getFlagMapName(type: FlagMapTypes, value: string) {
      const target = this.getFlagMapGroup(type);
      const result = findKey(target, (flagMapValue) => flagMapValue === value);

      if (!result) throw new Error(`[FlagMap] Couldn't find key by value(${value}) in FlagMap type ${type}`);

      return result;
    },

    /** 依屬性 (key) 取得特定 FlagMap 群組中的值 (value) */
    getFlagMapValue(type: FlagMapTypes, key: string) {
      const target = this.getFlagMapGroup(type);
      const result = target[key];

      if (!result) throw new Error(`[FlagMap] Couldn't find value by key(${key}) in FlagMap ${type}`);

      return result;
    }
  }
});
