<template>
  <button :type="type" :disabled="disabled" :class="rootClass" v-bind="inheritAttrs.attrsWithoutClass">
    <SIcon v-if="icon" :class="[iconSizeClass, slots.default && 'mr-1']" :icon="icon" />
    <slot />
  </button>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
/* 外部方法 */
import { computed, useSlots, useAttrs } from 'vue';
import { twMerge } from 'tailwind-merge';

/* 內部組件 */
import SIcon from '../SIcon/SIcon.vue';

/* 型別 */
interface Props {
  // 資料相關
  type?: 'submit' | 'reset' | 'button';

  // 畫面相關
  disabled?: boolean;
  size?: string;
  icon?: string;
}

const props = withDefaults(defineProps<Props>(), {
  // 資料相關
  type: 'button',

  // 畫面相關
  size: 'md',
  disabled: false
});

const slots = useSlots();

/* 樣式相關 */

// class 從繼承屬性中排除
const inheritAttrs = computed(() => {
  const { class: attrsClass, ...attrsWithoutClass } = useAttrs();

  return {
    attrsClass,
    attrsWithoutClass
  };
});

const buttonSizeClass = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'h-[20px] min-w-[36px] px-[8.88888889px] text-xs';
    case 'sm':
      return 'h-[28px] min-w-[50px] px-[12.4444444444px] text-sm';
    case 'md':
      return 'h-[36px] min-w-[64px] px-[16px] text-base';
    case 'lg':
      return 'h-[44px] min-w-[78px] px-[19.5555555556px] text-lg';
    case 'xl':
      return 'h-[52px] min-w-[92px] px-[23.1111111111px] text-xl';
    case '2xl':
      return 'h-[104px] min-w-[184px] px-[46.2222222222px] text-2xl';
    case '3xl':
      return 'h-[208px] min-w-[368px] px-[92.4444444444px] text-3xl';
    case '4xl':
      return 'h-[416px] min-w-[736px] px-[184.8888888888px] text-4xl';
    case '5xl':
      return 'h-[832px] min-w-[1472px] px-[369.7777777776px] text-5xl';
    default:
      return 'h-[36px] min-w-[64px] px-[16px] text-base';
  }
});

const iconSizeClass = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'h-4 w-4';
    case 'sm':
      return 'h-5 w-5';
    case 'md':
      return 'h-6 w-6';
    case 'lg':
      return 'h-7 w-7';
    case 'xl':
      return 'h-8 w-8';
    default:
      return 'h-6 w-6';
  }
});

const rootClass = computed(() => {
  const defaultClass =
    'base-btn relative inline-flex items-center justify-center rounded shadow-sm text-white border-0 focus:outline-none';
  const disabledClass = props.disabled ? 'bg-gray-300 text-gray-400 cursor-not-allowed' : '';

  return twMerge(defaultClass, buttonSizeClass.value, inheritAttrs.value.attrsClass as string, disabledClass);
});
</script>

<style lang="scss">
.base-btn {
  &:hover:before {
    opacity: 0.1;
  }
  &:before {
    background-color: #fff;
    border-radius: inherit;
    color: inherit;
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    right: 0;
    top: 0;
    transition: opacity 0.1s cubic-bezier(0.4, 0, 0.6, 1);
  }
}
</style>
