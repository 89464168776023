<template>
  <teleport to="body" :disabled="teleportDisabled">
    <transition
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      enter-active-class="ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      leave-active-class="ease-in duration-200"
    >
      <div v-if="modelValue" class="fixed top-0 left-0 w-full h-full overflow-y-auto" :style="containerStyle">
        <div class="flex items-center justify-center h-full pt-4 px-4 pb-20 text-center">
          <transition
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            enter-active-class="ease-out duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            leave-active-class="ease-in duration-200"
          >
            <div v-show="modelValue" class="absolute inset-0 transition-opacity">
              <div class="absolute inset-0 bg-zinc-500 opacity-75" @click.self="closable && onClose()" />
            </div>
          </transition>

          <transition
            enter-active-class="duration-300 ease-out"
            enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            enter-to-class="translate-y-0 opacity-100 sm:scale-100"
            leave-active-class="duration-200 ease-in"
            leave-class="translate-y-0 opacity-100 sm:scale-100"
            leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <div v-show="modelValue" :class="contentClassComputed" :style="contentStyle">
              <div v-if="closeBtn" class="flex justify-end">
                <SIcon
                  :icon="mdiClose"
                  class="w-5 h-5 text-gray-600 cursor-pointer hover:text-gray-400"
                  @click="onClose"
                />
              </div>

              <!-- Header -->
              <div
                v-if="slots.header || options.icon?.path || options.title || options.subtitle"
                :class="['pt-4 px-4 text-center', slots.default && 'pb-4']"
              >
                <slot name="header">
                  <div v-if="options.icon?.path" class="flex justify-center mb-3">
                    <SIcon :icon="options.icon.path" :class="options.icon.class" />
                  </div>

                  <h3 :class="twMerge(`text-lg leading-6 text-gray-900 ${options.titleClass}`)">
                    {{ options.title }}
                  </h3>

                  <div v-if="options.subtitle" :class="twMerge(`mt-2 ${options.subtitleClass}`)">
                    <p class="text-base text-gray-500">
                      {{ options.subtitle }}
                    </p>
                  </div>
                </slot>
              </div>

              <!-- Body -->
              <div v-if="slots.default" class="text-left p-4">
                <slot />
              </div>

              <!-- Footer -->
              <div v-if="slots.footer || options.cancelText || options.confirmText" class="flex justify-center p-4">
                <slot name="footer">
                  <SBtn
                    v-if="options.cancelText"
                    :class="
                      twMerge(
                        `w-40 bg-white !border border-gray-300 !text-gray-700 mr-2 whitespace-nowrap ${options.cancelClass}`
                      )
                    "
                    @click="onCancel"
                  >
                    {{ options.cancelText }}
                  </SBtn>

                  <SBtn
                    v-if="options.confirmText"
                    :class="twMerge(`w-40 bg-indigo-500 whitespace-nowrap ${options.confirmClass}`)"
                    @click="onConfirm"
                  >
                    {{ options.confirmText }}
                  </SBtn>
                </slot>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>
<script lang="ts" setup>
/* 外部方法 */
import { computed, useSlots } from 'vue';
import { twMerge } from 'tailwind-merge';

/* 內部方法 */
import nextZIndex from './utils';

/* 外部組件 */
import { mdiClose } from '@mdi/js';

/* 內部組件 */
import SIcon from '../SIcon/SIcon.vue';
import SBtn from '../SBtn/SBtn.vue';

/* 型別 */
import type { ModalOption } from './types';

interface Props {
  // 資料相關
  modelValue?: boolean;
  teleportDisabled?: boolean;

  // 畫面相關
  size?: string;
  closable?: boolean;
  closeBtn?: boolean;
  contentClass?: string;
  contentStyle?: string;
  options?: ModalOption;
  zIndex?: number | string;
}

interface Emits {
  (e: 'update:model-value', value: boolean): void;
  (e: 'onCancel'): void;
  (e: 'onConfirm'): void;
}

const props = withDefaults(defineProps<Props>(), {
  // 資料相關
  modelValue: false,
  teleportDisabled: false,

  // 畫面相關
  size: 'lg',
  closable: true,
  closeBtn: false,
  zIndex: '',
  options: () => ({} as ModalOption)
});

const emit = defineEmits<Emits>();

const slots = useSlots();

const onCancel = () => emit('onCancel');
const onConfirm = () => emit('onConfirm');
const onClose = () => emit('update:model-value', false);

/**
 * 樣式相關
 */

// 寬度設定
const width = computed(() => {
  switch (props.size.toLowerCase()) {
    case 'xs':
      return 'sm:max-w-xs';
    case 'sm':
      return 'sm:max-w-sm';
    case 'md':
      return 'sm:max-w-md';
    case 'lg':
      return 'sm:max-w-lg';
    case 'xl':
      return 'sm:max-w-xl';
    case '2xl':
      return 'sm:max-w-2xl';
    case '3xl':
      return 'sm:max-w-3xl';
    case '4xl':
      return 'sm:max-w-4xl';
    case '5xl':
      return 'sm:max-w-5xl';
    case 'full':
      return 'sm:max-w-full';
    default:
      return 'sm:max-w-sm';
  }
});

const contentClassComputed = computed(() => {
  const defaultClass =
    'bg-white w-full rounded-lg max-h-[80%] overflow-hidden overflow-y-auto shadow-xl transform transition-all';
  return twMerge(defaultClass, width.value, props.contentClass, props.options.contentClass);
});

const containerStyle = computed(() => `z-index: ${props.zIndex || nextZIndex()}`);
</script>
