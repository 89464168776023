/* 外部方法 */
import { cloneDeep, sortBy } from 'lodash-es';

/* 型別 */
import type { Router, RouteRecordRaw } from 'vue-router';
import type SiderbarItem from '@sms/common/interfaces/SiderbarItem';

/**
 * 排序 Siderbar
 */
const sortSiderbar = (siderbars: SiderbarItem[]) => {
  siderbars.forEach((item) => {
    if (item.SubMenu.length) {
      item.SubMenu = sortBy(item.SubMenu, ['Sort']);
      sortSiderbar(item.SubMenu);
    }
  });

  return siderbars;
};

/**
 * 針對 FeatureType 為後台的功能產生路由
 */
const generateRoutes = (siderbars: SiderbarItem[]): RouteRecordRaw[] => {
  const routes: RouteRecordRaw[] = [];

  siderbars.forEach((siderbar) => {
    if (siderbar.SubMenu?.length) routes.push(...generateRoutes(siderbar.SubMenu));
    if (siderbar.FeatureTypeCode !== '4') return;

    const featureRoutes = [
      {
        path: `/${siderbar.Uri}`,
        name: siderbar.Code,
        component: () => import(`../views/${siderbar.Code}/index.vue`),
        props: true,
        meta: { requirePermission: true, ...siderbar }
      },
      {
        path: `/${siderbar.Uri}/create`,
        name: `${siderbar.Code}-create`,
        component: () => import(`../views/${siderbar.Code}/edit.vue`),
        props: true,
        meta: { requirePermission: true, ...siderbar }
      },
      {
        path: `/${siderbar.Uri}/edit/:input`,
        name: `${siderbar.Code}-edit`,
        component: () => import(`../views/${siderbar.Code}/edit.vue`),
        props: true,
        meta: { requirePermission: true, ...siderbar }
      }
    ];

    routes.push(...featureRoutes);
  });

  return routes;
};

/**
 * 將後端回傳的 Siderbars 轉為 Route 新增至路由
 */
export const addRoutes = (router: Router, siderbars: SiderbarItem[]) => {
  const sortedSiderbars = sortSiderbar(cloneDeep(siderbars));
  const routes = generateRoutes(sortedSiderbars);

  routes.forEach((route) => router.addRoute('systemPages', route));
};

/**
 * 驗證權限
 * @param uri 指定要查詢的權限 URI
 * @param sidebars 後端回傳的 siderbar 資料
 */
export const hasPermission = (uri: string | undefined, siderbars: SiderbarItem[]): boolean => {
  return siderbars.some((siderbar) => {
    if (siderbar.Uri === uri) return true;
    if (siderbar.SubMenu.length) return hasPermission(uri, siderbar.SubMenu);
    return false;
  });
};

/**
 * 驗證功能權限
 * @param uri 指定要查詢的權限 URI
 * @param authType 功能代碼 (1, 2, 4, 8)
 * @param sidebars 後端回傳的 siderbar 資料
 */
export const hasAuthPermission = (uri: string | undefined, authType: number, siderbars: SiderbarItem[]): boolean => {
  return siderbars.some((siderbar) => {
    const hasAuthMethod = siderbar.AuthMaps.map((authMap) => authMap.AuthCode).includes(authType);

    if (siderbar.Uri === uri && hasAuthMethod) return true;
    if (siderbar.SubMenu.length) return hasAuthPermission(uri, authType, siderbar.SubMenu);

    return false;
  });
};
