<template>
  <div v-if="show" class="absolute inset-0 z-50 flex flex-col items-center justify-center" :style="setting[site].style">
    <div
      class="text-4xl font-normal bg-gradient-to-b from-indigo-400 to-sky-200 bg-clip-text mb-20"
      style="-webkit-text-fill-color: transparent"
    >
      {{ setting[site].title }}
    </div>

    <!-- 讀取循環條 -->
    <div class="progress flex items-center justify-center w-full max-w-2xl h-5 rounded-[10px] px-1 border mb-3">
      <div class="relative w-full h-[10px] rounded-[10px] bg-white bg-opacity-[0.15] overflow-hidden">
        <div>
          <div class="progress__indeterminate__long" />
          <div class="progress__indeterminate__short" />
        </div>
      </div>
    </div>

    <div class="mb-[110px] text-gray-400">Loading...</div>

    <!-- 重置按鈕 -->
    <div v-if="reload" class="flex flex-col items-center">
      <SBtn
        :icon="mdiRefresh"
        class="h-[45px] !rounded-lg bg-white text-indigo-400 font-bold mb-5 btn"
        @click="router.go(0)"
      >
        Reload
      </SBtn>

      <p class="text-white">System loading error, please click the button below to reload.</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

/* 外部組件 */
import { mdiRefresh } from '@mdi/js';

/* 內部組件 */
import SBtn from '@sms/components/SBtn/SBtn.vue';

/* 型別 */
interface Props {
  show?: boolean;
  reload?: boolean;
  site?: 'admin' | 'tablet' | 'dealer' | 'shuffler';
  apiCallback?: () => Promise<void>;
}

interface Emits {
  (e: 'update:show', value: boolean): void;
  (e: 'update:reload', value: boolean): void;
}

const props = withDefaults(defineProps<Props>(), {
  show: false,
  reload: false,
  site: 'admin'
});

const emit = defineEmits<Emits>();

/* 系統元件 */
const router = useRouter();

/** 設定檔 */
const setting = {
  admin: {
    title: 'Admin',
    style: 'background: linear-gradient(195.03deg, #6366f1 -37.63%, #1f2937 76.08%)'
  },

  tablet: {
    title: 'Tablet Interface',
    style: ''
  },

  dealer: {
    title: 'Dealer Interface',
    style: 'background: linear-gradient(195.03deg, #6366f1 -37.63%, #1f2937 76.08%)'
  },

  shuffler: {
    title: 'Shuffle Room System',
    style: 'background: linear-gradient(195.03deg, #6366f1 -37.63%, #1f2937 76.08%)'
  }
};

const initData = async () => {
  try {
    emit('update:show', true);

    if (props.apiCallback) {
      await props.apiCallback();
    }

    emit('update:show', false);
  } catch (error) {
    emit('update:reload', true);
  }
};

onMounted(initData);
</script>

<style scoped>
.btn {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3) !important;
}

.progress {
  border-color: rgba(255, 255, 255, 0.25);
}

.progress__indeterminate__long,
.progress__indeterminate__short {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  width: auto;
  background: linear-gradient(269.67deg, #ede9fe -49.96%, #bab9f9 5.65%, #6366f1 100.61%);
  box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.2);
  height: inherit;
  animation-play-state: running;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  border-radius: 10px;
}

.progress__indeterminate__long {
  animation-name: indeterminate-ltr;
}

.progress__indeterminate__short {
  animation-name: indeterminate-short-ltr;
}

@keyframes indeterminate-ltr {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes indeterminate-short-ltr {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
</style>
